.full-screen-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.faq {
    background-image: url('/public/faqbg.png'); /* Replace with the actual path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed; /* Fix the background */
    max-width: 2000px;
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px; /* Adjust padding for smaller screens */
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}


.faq-title {
    font-size: 2.5rem;
    text-align: center; /* Center-align for better small-screen layout */
    color: powderblue;
    font-weight: bold;
    font-family: "Bungee", sans-serif;
    margin-bottom: 20px;
}

.faq-divider {
    width: 90%;
    max-width: 800px;
    height: 3px;
    background-color: powderblue;
    margin: 10px auto; /* Center the divider */
    opacity: 0.8;
}

.faq-list {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
    background: transparent;
    border-radius: 5px;
    font-family: "Genos", sans-serif;
    color: powderblue;
    transition: color 0.3s ease;
    text-align: left;
}

.faq-question:hover {
    color: powderblue;
}

.faq-icon {
    font-size: 1.2rem;
    color: powderblue;
    transition: color 0.3s ease;
}

.faq-answer {
    margin-top: 10px;
    padding: 10px;
    font-size: 1rem;
    background: transparent;
    color: powderblue;
    border-radius: 5px;
    font-family: "Genos", sans-serif;
    line-height: 1.5;
    transition: opacity 0.3s ease;
    text-align: left;
}

.faq-answer p {
    margin: 0;
}

.faq-item-divider {
    width: 100%;
    height: 1px;
    background-color: powderblue;
    margin: 10px 0;
    opacity: 0.8;
}

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
    .faq {
        padding: 40px 20px;
    }

    .faq-title {
        font-size: 2.2rem;
    }

    .faq-question {
        font-size: 1.1rem;
        padding: 8px;
    }

    .faq-answer {
        font-size: 1rem;
        padding: 8px;
    }
}

/* Small Screens (Mobile) */
@media (max-width: 768px) {
    .faq-title {
        font-size: 2rem;
        margin-bottom: 15px;
    }

    .faq-divider {
        width: 80%;
    }

    .faq-question {
        font-size: 1rem;
        padding: 6px;
    }

    .faq-answer {
        font-size: 0.95rem;
        padding: 6px;
    }
}

/* Extra Small Screens (Very Small Mobile) */
@media (max-width: 480px) {
    .faq-title {
        font-size: 1.8rem;
        text-align: center;
    }

    .faq-divider {
        width: 70%;
    }

    .faq-question {
        font-size: 0.9rem;
    }

    .faq-answer {
        font-size: 0.85rem;
    }
}
