.navbar {
  position: absolute; /* Adjust as needed for design */
  width: 100%; /* Set to full width of the viewport */
  max-width: 2000px; /* Maximum width for larger screens */
  background: #231F1F;
  padding: 0.8rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Keeps it above other elements */
  margin: 0 auto; /* Center on larger screens */
  box-sizing: border-box; /* Ensures padding is included in width calculations */
}

.navbar-logo {
  cursor: pointer; /* Ensures pointer cursor on logo */
}

.navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem; /* Smaller size for better scaling */
  color: #b0e0e6; /* Powder blue */
  font-family: 'Bungee', sans-serif;
}

.navbar-links a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.navbar-links a:hover {
  transform: scale(1.1); /* Slight scale effect on hover */
}

.navbar-logo img {
  width: 50px; /* Logo width */
  height: 50px; /* Logo height */
  transition: transform 0.3s ease; /* Smooth scale animation */
}

/* Logo Hover Effect */
.navbar-logo img:hover {
  transform: scale(1.1); /* Scales up the logo slightly */
}

.navbar ul {
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  font-weight: bold;
  font-size: 1.2rem;
}

/* Buy Now Button */
.navbar .buy-now {
  padding: 5px 30px 7px;
  font-family: 'Bungee', sans-serif;
  font-size: 1rem; /* Adjusted for responsiveness */
  color: #231F1F;
  background-image: url('/public/buynow.png'); /* Update with the correct path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar .buy-now:hover {
  background-color: rgba(122, 44, 145, 0.8);
  color: #ffffff;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .navbar-links {
    font-size: 1rem; /* Adjust font size */
    gap: 0.8rem; /* Reduce spacing */
  }
  
  .navbar .buy-now {
    font-size: 0.9rem;
    padding: 4px 20px 6px; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    padding: 1rem; /* Increase padding for touch screens */
  }
  
  .navbar-logo img {
    width: 40px;
    height: 40px;
  }
  
  .navbar-links {
    flex-direction: column; /* Stack links vertically */
    gap: 0.5rem; /* Reduce spacing further */
  }
}

@media (max-width: 480px) {
  .navbar-links {
    font-size: 0.9rem;
  }
  
  .navbar .buy-now {
    font-size: 0.8rem;
    padding: 3px 15px 5px; /* Further adjustments for smaller screens */
  }
}
