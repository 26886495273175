/* General Hero Styling */
.hero {
  position: relative;
  width: 100%;
  height: 130vh; /* Ensures it covers the full viewport height */
  overflow: hidden; /* Hides any overflow outside the container */
}

/* Background Image */
.hero-background {
  position: absolute;
  inset: 0;
  z-index: -1; /* Ensures it stays behind other elements */
}

.hero-background img {
  width: 100%; /* Covers the entire width */
  height: 100%; /* Covers the entire height */
  object-fit: cover; /* Scales the image while maintaining aspect ratio */
  object-position: center; /* Centers the image within the container */
}

/* Hero Content */
.hero-content {
  position: absolute;
  bottom: 9rem;
  left: 10rem;
  min-width: 530px;
  background-color: #242020;
  padding: 1.4rem;
  border-radius: 10px;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.hero-content.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.text-wrapper h1 {
  margin: 0;
  font-size: 3rem;
  font-family: 'Bungee', sans-serif;
  color: powderblue;
  line-height: 1;
}

.text-wrapper h1 span {
  display: block;
}

.text-wrapper .parrot-town {
  font-size: 3rem;
  color: powderblue;
  font-weight: bold;
}

.text-wrapper b {
  font-size: 1.2rem;
  font-family: 'Lexend Deca', sans-serif;
  color: powderblue;
  display: block;
  margin-top: 1.1rem;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .hero-content {
    bottom: 2rem;
    left: 1rem;
    min-width: auto;
  }

  .text-wrapper h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .hero-content {
    bottom: 1rem;
    left: 1rem;
    padding: 1rem;
  }

  .text-wrapper h1 {
    font-size: 2rem;
  }

  .text-wrapper b {
    font-size: 1rem;
  }
}
