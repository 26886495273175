/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Bungee:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Genos:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@900&display=swap");

/* Local font declaration */
@font-face {
  font-family: "Ultramoon Demo";
  src: url("/public/UltramoonDemoRegular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

/* Body font */
body {
  margin: 0;
  font-family: "Lexend Deca", -apple-system, BlinkMacSystemFont, 'Segoe UI', 
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

/* Code font */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Font utility classes */
.font-bungee {
  font-family: "Bungee", sans-serif;
}

.font-lexend {
  font-family: "Lexend Deca", sans-serif;
}

.font-genos {
  font-family: "Genos", sans-serif;
}

.font-unbounded {
  font-family: "Unbounded", sans-serif;
  font-weight: 900;
}

.font-ultramoon {
  font-family: "Ultramoon Demo", sans-serif;
}

/* Colors */
.text-gray-100 {
  color: #242527;
}

.text-gray-200 {
  color: #242020;
}

.text-gray-300 {
  color: #231f1f;
}

.text-gray-400 {
  color: #201b1b;
}

.bg-gray-100 {
  background-color: #242527;
}

.bg-gray-200 {
  background-color: #242020;
}

.bg-gray-300 {
  background-color: #231f1f;
}

.bg-gray-400 {
  background-color: #201b1b;
}

.text-powderblue-100 {
  color: #afdcd6;
}

.text-powderblue-200 {
  color: #96c2bd;
}

.bg-powderblue-100 {
  background-color: #afdcd6;
}

.bg-powderblue-200 {
  background-color: #96c2bd;
}

/* Font Sizes */
.text-xl {
  font-size: 20px;
}

.text-lgi {
  font-size: 19px;
}

.text-lg {
  font-size: 18px;
}

.text-2xl {
  font-size: 21px;
}

.text-10xl {
  font-size: 29px;
}

.text-29xl {
  font-size: 48px;
}

.text-19xl {
  font-size: 38px;
}

.text-38xl {
  font-size: 57px;
}

.text-4xl {
  font-size: 23px;
}

.text-13xl {
  font-size: 32px;
}

.text-44xl {
  font-size: 63px;
}

.text-base {
  font-size: 16px;
}

.text-7xl {
  font-size: 26px;
}

.text-inherit {
  font-size: inherit;
}

/* Screen Breakpoints */
/* Screen Breakpoints */

@media (max-width: 1800px) {
  .lg\:max-w {
    max-width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .mq1050\:max-w {
    max-width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .mq750\:hidden {
    display: none;
  }
}


@import url("https://fonts.googleapis.com/css2?family=Bungee:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Genos:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ultramoon+Demo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  background-color: #1a1a1a;
  min-height: 100vh; /* Ensure minimum viewport height */
  position: relative; /* For footer positioning */
}
:root {
  /* fonts */
  --font-genos: Genos;
  --font-bungee: Bungee;
  --font-lexend-deca: "Lexend Deca";
  --font-unbounded: Unbounded;
  --font-ultramoon-demo: "Ultramoon Demo";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-sm: 14px;
  --font-size-11xl: 30px;

  /* Colors */
  --color-gray-100: #242527;
  --color-gray-200: #242020;
  --color-powderblue: #afdcd6;

  /* Gaps */
  --gap-10xs: 3px;
  --gap-4xs: 9px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-base: 16px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-6xs: 7px;
  --padding-lg: 18px;
  --padding-base: 16px;
}
