.contactUs,
.lookingToGet {
  align-self: stretch;
  position: relative;
}
.contactUs {
  font-size: 19px;
  font-weight: 300;
  font-family: var(--font-lexend-deca);
}
.lookingToGetInvolvedParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.yourName {
  width: 299px;
  position: relative;
  display: inline-block;
}
.frameChild {
  width: 299px;
  height: 37px;
  object-fit: cover;
}
.eMailIdParent,
.yourNameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.eMailIdParent {
  gap: var(--gap-10xs);
}
.frameInner {
  width: 299px;
  height: 133px;
  object-fit: cover;
}
.frameContainer {
  width: 299px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
}
.supportTeamWill {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  font-family: var(--font-genos);
}
.frameGroup {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-base);
  font-family: var(--font-lexend-deca);
}
.contact,
.frameGroup,
.frameParent {
  display: flex;
  justify-content: flex-start;
}
.frameParent {
  width: 324px;
  flex-direction: column;
  align-items: flex-start;
  gap: 61px;
}
.contact {
  position: absolute;
  top: 6176px;
  left: 0;
  width: 400px;
  height: 748px;
  flex-direction: row;
  align-items: center;
  padding: 141px var(--padding-lg) 123px;
  box-sizing: border-box;
  background-image: url(/public/mob/contact@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  color: var(--color-gray-100);
}
.ofArasCanopy {
  align-self: stretch;
  position: relative;
  font-size: 15px;
  letter-spacing: 0.17em;
  font-family: var(--font-lexend-deca);
}
.unveilingStoryParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.contraryToPopular {
  width: 251px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  height: 409px;
  flex-shrink: 0;
}
.contraryToPopularBeliefLoWrapper {
  align-self: stretch;
  height: 404px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
}
.frameParent1 {
  width: 295px;
  height: 768px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 276px;
  z-index: 0;
}
.groupIcon {
  width: 20.6px;
  position: relative;
  height: 23.4px;
  object-fit: contain;
}
.frameDiv,
.groupParent {
  width: 339px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.groupParent {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 798px;
  z-index: 1;
}
.frameDiv {
  padding: 0 21px;
  box-sizing: border-box;
  position: relative;
}
.about,
.groupChild {
  position: absolute;
  left: 0;
}
.about {
  top: 726px;
  width: 400px;
  height: 920px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 68px var(--padding-3xs);
  box-sizing: border-box;
  background-image: url(/public/mob/about@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.groupChild {
  top: 0;
  width: 356.5px;
  height: 113.9px;
  object-fit: contain;
}
.joinThe {
  white-space: pre-wrap;
}
.span {
  font-weight: 900;
  font-family: var(--font-unbounded);
}
.joinTheFlock {
  margin: 0;
}
.joinTheFlockContainer {
  position: absolute;
  top: 47.4px;
  left: 15.3px;
  line-height: 110%;
  display: inline-block;
  width: 293.5px;
  height: 33.3px;
  transform: rotate(-0.3deg);
  transform-origin: 0 0;
}
.groupDiv {
  width: 356.5px;
  position: relative;
  height: 113.9px;
  text-align: center;
}
.inArasCanopy {
  flex: 1;
  position: relative;
}
.frameWrapper,
.inArasCanopyTheHeartOfWrapper {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.inArasCanopyTheHeartOfWrapper {
  background-color: var(--color-gray-200);
  height: 112px;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 15px;
  box-sizing: border-box;
}
.frameWrapper {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size-sm);
  font-family: var(--font-genos);
}
.frameChild2 {
  width: 186px;
  position: absolute;
  margin: 0 !important;
  top: 3.5px;
  left: 0;
  height: 47.5px;
  object-fit: cover;
  z-index: 0;
}
.getAParrot {
  position: relative;
  z-index: 1;
}
.groupParent1 {
  width: 186px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 39px var(--padding-base) 34px;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
  font-family: var(--font-bungee);
}
.frameChild3,
.subtractIcon {
  width: 33.6px;
  height: 33.6px;
  position: relative;
  object-fit: cover;
  pointer-events: auto;
}

.frameChild3 {
  object-fit: cover;
}
.subtractParent {
  display: flex; /* Explicitly make it a flex container */
  width: auto; /* Ensure it adapts to content size */
  flex-direction: row; /* Keep links in a row */
  align-items: center; /* Center alignment if necessary */
  gap: 20px; /* Provide consistent spacing */
  position: relative; /* Makes z-index adjustments applicable */
  margin-bottom: 40px;
}

.subtractParent a {
  display: inline-block;
  width: 33.6px; /* Match image width */
  height: 33.6px; /* Match image height */
  cursor: pointer; /* Ensures clickability */
  position: relative; /* Allows the image inside to adjust correctly */
  z-index: 10; /* Ensures it stays on top */
}

.groupContainer,
.join,
.subtractParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.groupContainer {
  width: 294px;
  flex-direction: column;
  gap: var(--gap-3xs);
}
.join {
  position: absolute;
  top: 4505px;
  left: 0;
  width: 400px;
  height: 888px;
  flex-direction: row;
  padding: 432px 31px 158px;
  box-sizing: border-box;
  background-image: url(/public/mob/join@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 34px;
  font-family: var(--font-ultramoon-demo);
}
.frameChild4 {
  width: 1200px;
  position: relative;
  height: 222px;
  object-fit: contain;
}
.footerInner {
  width: 400px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.frameChild5,
.subtractIcon1 {
  width: 36.8px;
  position: relative;
  height: 36.8px;
}
.frameChild5 {
  object-fit: cover;
}
.footer,
.frameParent2,
.subtractGroup {
  display: flex;
  justify-content: flex-start;
}
.subtractGroup {
  width: 102px;
  flex-direction: row;
  align-items: center;
  gap: 29px;
}
.footer,
.frameParent2 {
  flex-direction: column;
}
.frameParent2 {
  width: 327px;
  align-items: center;
  gap: 4px;
  z-index: 1;
}
.footer {
  position: absolute;
  top: 6862px;
  left: 0;
  width: 400px;
  height: 222px;
  align-items: flex-start;
  padding: 124px var(--padding-base) 48px;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
}
.frameChild6 {
  width: 1200px;
  position: relative;
  height: 772px;
  object-fit: cover;
}
.heroInner {
  width: 400px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 772px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.vectorIcon {
  width: 34px;
  position: relative;
  height: 32px;
}
.frameChild7,
.frameChild8 {
  width: 18px;
  position: relative;
  max-height: 100%;
}
.frameChild8 {
  width: 22.5px;
}
.frameWrapper2,
.vectorGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.vectorGroup {
  width: 30px;
  height: 30px;
  align-items: center;
  gap: 7px;
}
.frameWrapper1,
.vectorParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameWrapper1 {
  background-color: #231F1F;
  height: 74px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 19px 27px var(--padding-3xs) var(--padding-lg);
  box-sizing: border-box;
}
.pagesFromParrot,
.punkParrotsIn {
  position: relative;
  display: inline-block;
}
.pagesFromParrot {
  align-self: stretch;
  line-height: 88.7%;
  height: 74px;
  flex-shrink: 0;
}
.punkParrotsIn {
  width: 279px;
  font-size: 13px;
  letter-spacing: 0.31em;
  font-weight: 800;
  font-family: var(--font-lexend-deca);
}
.frameWrapper3,
.pagesFromParrotTownParent {
  width: 289px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper3 {
  width: 322px;
  background-color: var(--color-gray-200);
  height: 134px;
  justify-content: center;
  padding: 0 0 0 17px;
  box-sizing: border-box;
}
.frameParent3,
.hero {
  width: 400px;
  display: flex;
  align-items: center;
}
.frameParent3 {
  flex-direction: column;
  justify-content: flex-start;
  gap: 312px;
  z-index: 1;
}
.hero {
  position: absolute;
  top: 0;
  left: 0;
  height: 772px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 252px;
  box-sizing: border-box;
  text-align: center;
  font-size: 38px;
}
.frameChild10 {
  width: 1582px;
  position: relative;
  height: 3097.3px;
  object-fit: cover;
}
.storyInner {
  width: 400px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.frameChild11 {
  width: 297.4px;
  position: relative;
  height: 2386.4px;
}
.welcomeToThe {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
  display: inline-block;
  height: 94px;
  flex-shrink: 0;
}
.clearanceStatusParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 23px 0 0;
  gap: var(--gap-5xs);
}
.getReadyTo {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
  display: inline-block;
  height: 59px;
  flex-shrink: 0;
}
.advantagesOfBuyingThisNftParent {
  align-self: stretch;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.ellipseDiv {
  width: 12px;
  position: relative;
  border-radius: 50%;
  border: 9px solid var(--color-powderblue);
  box-sizing: border-box;
  height: 12px;
}
.ellipseWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0;
}
.whySettleForContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
  display: inline-block;
  height: 114px;
  flex-shrink: 0;
}
.number01ThePowerOfAugmeParent {
  width: 244px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent8 {
  align-self: stretch;
  height: 307px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
}
.onceAPartContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
  display: inline-block;
  height: 309px;
  flex-shrink: 0;
}
.number02FlockMembershipParent {
  width: 236px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent9 {
  align-self: stretch;
  height: 401px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
}
.number03SecretUpdatesAndParent {
  width: 241px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent10 {
  align-self: stretch;
  height: 332px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
}
.number04GovernanceAndDecParent {
  width: 231px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent11,
.frameParent12 {
  align-self: stretch;
  height: 269px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
}
.frameParent12 {
  height: 229px;
}
.number06AirdropsAndSurprParent {
  width: 249px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent13 {
  align-self: stretch;
  height: 249px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
}
.frameParent7 {
  width: 261px;
  gap: 37px;
  font-size: var(--font-size-lg);
}
.frameParent5,
.frameParent6,
.frameParent7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent6 {
  align-self: stretch;
  padding: 0 0 0 var(--padding-xl);
  gap: 23px;
  text-align: left;
}
.frameParent5 {
  width: 297px;
  height: 2386px;
  gap: 63px;
}
.frameWrapper5 {
  margin-left: -837px;
}
.frameWrapper4,
.frameWrapper5,
.groupParent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameWrapper4 {
  align-self: stretch;
  padding: var(--padding-3xs);
}
.inArasCanopy1 {
  width: 275px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  flex-shrink: 0;
}
.inArasCanopyTheSkyIsntWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameWrapper6 {
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs);
  text-align: center;
  font-size: var(--font-size-base);
}
.frameParent4,
.frameWrapper6,
.story {
  display: flex;
  align-items: center;
}
.frameParent4 {
  width: 317.4px;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}
.story {
  position: absolute;
  top: 1562px;
  left: 0;
  width: 400px;
  height: 3097.3px;
  flex-direction: row;
  justify-content: space-between;
  padding: 259px var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-3xl);
}
.frameChild17 {
  width: 1030px;
  position: relative;
  height: 1000px;
  object-fit: cover;
}
.faqInner {
  width: 400px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.frameChild18 {
  width: 320px;
  position: relative;
  max-height: 100%;
}
.whatIsAras {
  width: 319px;
  position: relative;
  display: inline-block;
  height: 23px;
  flex-shrink: 0;
}
.polygon3Stroke {
  width: 12px;
  position: relative;
  height: 5.6px;
  object-fit: contain;
  margin-left: -96px;
}
.whatIsArasCanopyParent {
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameParent17 {
  align-self: stretch;
  align-items: center;
}
.frameParent14,
.frameParent16,
.frameParent17 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent16 {
  align-self: stretch;
  align-items: flex-start;
  gap: 27px;
  font-size: var(--font-size-base);
  font-family: var(--font-genos);
}
.frameParent14 {
  width: 320px;
  align-items: center;
  gap: 47px;
  z-index: 1;
}
.faq
 {
  position: absolute;
}
.faq {
  top: 5235px;
  left: 0;
  width: 400px;
  height: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 214px var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: 29px;
}
.mobChild {
  position: fixed; /* Keeps the element fixed relative to the viewport */
  bottom: 20px; /* Adjust the spacing from the bottom */
  right: 20px; /* Adjust the spacing from the right */
  width: 40px; /* Adjust size as per design */
  height: 40px; /* Adjust size as per design */
  cursor: pointer; /* Makes it clear the element is clickable */
  z-index: 1000; /* Ensures it stays above other elements */
}

.mob {
  width: 100vw; /* Forces the element to take the full width of the viewport */
  position: relative;
  background-color: #201b1b;
  height: 7084px;
  overflow: hidden;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--color-powderblue);
  font-family: var(--font-bungee);
  margin: 0; /* Reset margins */
  padding: 0; /* Reset paddings */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
}
