.fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }
  
  .fixed-button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  