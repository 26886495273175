* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  min-height: 100vh; /* Ensure the body takes at least the full viewport height */
  width: 100%; /* Make the body width responsive */
  max-width: 2000px; /* Set a maximum width for large screens */
  margin: 0 auto; /* Center the content for larger screens */
  background-color: #f8f9fa; /* Optional: Set a neutral background color */
}

/* Responsive styles for desktop */
@media (max-width: 2000px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 1440px) {
  body {
    font-size: 12px;
  }
}
