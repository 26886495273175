/* Contact Section Styles */
.contact {
  background-image: url("/public/Contact.png");
  background-size: cover; /* Ensure it covers the full width and height */
  background-position: center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat;
  background-attachment:scroll;
  width: 100%;
  min-height: 130vh;
  position: relative;
  padding-bottom: 100px;
}


/* Ensure Content Stays Well-Positioned */
.contact-container {
  max-width: 1400px; /* Increased from 1024px */
  margin: 0 auto;
  position: relative;
  padding: 2rem;
  z-index: 2;
}

.coming-soon-text {
  position: absolute;
  top: 360px; /* Adjusted from 30% */
  right: 5%;
  text-align: right;
}

.coming-soon-title {
  font-size: 4.5rem;
  font-weight: bold;
  color: #ADE1C7;
  font-family: 'Bungee', cursive;
  white-space: nowrap;
}

.coming-soon-subtitle {
  font-size: 2rem;
  color: #ADE1C7;
  font-family: 'Bungee', sans-serif;
  margin-top: 1rem;
}

.collection-drop {
  display: block;
  font-family: 'Lexend Deca', regular;
  font-weight: 25;
  color: #ADE1C7;
}

/* Footer Styles */
.footer-background {
  background-color: #231F1F;
  width: 100%;
  padding: 20px 0; /* Increased padding */
  position: relative; /* Changed from absolute to relative */
  bottom: 0;
  left: 0;
  margin-top: auto; /* Added to push footer to bottom */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0;
}

.footer-icons {
  display: flex;
  gap: 20px;
}

.footer-icon {
  width: 50px;
  height: 50px;
}

.footer-icon:hover {
  transform: scale(1.1) rotate(5deg);
  transition: transform 0.3s ease;
}

.footer-text {
  font-size: 16px;
  color: powderblue;
  font-family: 'Genos', sans-serif;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact {
    min-height: 100vh;
    background-size: 80% auto; /* Adjust background size for mobile */
    padding-bottom: 150px;
  }

  .coming-soon-text {
    right: 10%;
    top: 50%;
  }

  .coming-soon-title {
    font-size: 3rem;
  }

  .coming-soon-subtitle {
    font-size: 1.5rem;
  }

  .footer-text {
    font-size: 14px;
  }
}