/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Genos:wght@300;400;500&display=swap');

.advantages-container {
  position: relative;
  min-height: 100vh;
  background-image: url('/public/advantagesbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #AFDCD6;
  padding: 1rem;
  overflow: hidden;
  background-attachment: fixed; /* Fix the background */
}

.advantages-content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  z-index: 2;
}

.horizontal-line.below-top {
  margin-top: 90px;
  width: 83%;
  height: 3.5px;
  background-color: powderblue;
  margin-left: 32px;
}

.horizontal-line.above-bottom {
  width: 52%;
  height: 4px;
  margin-top: 8px;
  margin-left: -62px;
  background-color: powderblue;
}

.top-connection {
  position: absolute;
  top: 70px;
  right: 20%;
  height: 50px;
}

.top-connection .connection-line {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 178%;
  background-color: #AFDCD6;
}

.top-connection .connection-dot {
  position: absolute;
  right: -4px;
  top: 0;
  width: 12px;
  height: 12px;
  background-color: #AFDCD6;
  border-radius: 50%;
  box-shadow: 0 0 10px #AFDCD6;
}

.header-connection {
  position: relative;
  padding-right: 4rem;
  margin-bottom: 4rem;
  text-align: right;
}

.header-content {
  position: relative;
}

.header-connection h3 {
  font-family: 'Bungee', sans-serif;
  font-size: 2rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0.05em;
}

.header-connection p {
  font-family: 'Genos', sans-serif;
  font-size: 1.2rem;
  line-height: 1.4;
  color: rgba(175, 220, 214, 0.9);
  margin-top: 60px;
  margin-left: 80px;
  text-align: end;
}

.main-content {
  position: relative;
  padding-left: 4rem;
  margin-left: 2rem;
}

.main-content::before {
  content: '';
  position: absolute;
  left: 0;
  top: -180px;
  width: 4px;
  height: 101.4%;
  background-color: #AFDCD6;
}

.main-title {
  font-family: 'Bungee', sans-serif;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
  margin-right: 100px;
}

.main-subtitle {
  font-family: 'Genos', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  line-height: 1.6;
  color: rgba(175, 220, 214, 0.9);
}

.advantages-list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.advantage-item {
  position: relative;
  padding-left: 1rem;
}

.advantage-item::before {
  content: '';
  position: absolute;
  left: -0.7rem;
  top: 0.5rem;
  width: 8px;
  height: 8px;
  background-color: #AFDCD6;
  border-radius: 50%;
  box-shadow: 0 0 10px #AFDCD6;
}

.advantage-item h3 {
  font-family: 'Bungee', sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
}

.advantage-item p {
  font-family: 'Genos', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 0.975rem;
  color: rgba(175, 220, 214, 0.9);
}

.footer-quote {
  margin-top: 6rem;
  text-align: center;
  padding: 0 2rem;
}

.footer-quote p {
  font-family: 'Bungee', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
}

.bottom-connection {
  position: absolute;
  bottom: 60px;
  left: 550px;
  height: 70px;
}

.bottom-connection .connection-line {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 4px;
  height: 116%;
  background-color: #AFDCD6;
}

.bottom-connection .connection-dot {
  position: absolute;
  left: -4px;
  bottom: 20px;
  width: 12px;
  height: 12px;
  background-color: #AFDCD6;
  border-radius: 50%;
  box-shadow: 0 0 10px #AFDCD6;
}

.connection-dot,
.advantage-item::before {
  animation: glow 2s infinite alternate;
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.main-content {
  opacity: 0;
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.slide-in {
  opacity: 0;
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.slide-in[data-delay="1"] {
  animation-delay: 0.2s;
}
.slide-in[data-delay="2"] {
  animation-delay: 0.4s;
}
.slide-in[data-delay="3"] {
  animation-delay: 0.6s;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .advantages-container {
    padding: 1rem;
  }

  .advantages-content {
    padding: 1rem;
  }

  .header-connection {
    padding-right: 1rem;
    margin-bottom: 2rem;
  }

  .main-content {
    padding-left: 1rem;
    margin-left: 0;
  }

  .footer-quote {
    padding: 0 1rem;
  }

  .main-title {
    font-size: 1.5rem;
    margin-right: 0;
  }

  .advantage-item h3 {
    font-size: 0.9rem;
  }

  .advantage-item p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header-connection h3 {
    font-size: 1.5rem;
  }

  .header-connection p {
    font-size: 1rem;
    margin-top: 20px;
    margin-left: 0;
    text-align: center;
  }

  .main-title {
    font-size: 1.25rem;
  }

  .main-subtitle {
    font-size: 1rem;
  }

  .advantages-list {
    gap: 1.5rem;
  }

  .footer-quote p {
    font-size: 1rem;
  }

  .top-connection {
    right: 10%;
    top: 50px;
  }

  .bottom-connection {
    left: 50%;
    transform: translateX(-50%);
  }
}
