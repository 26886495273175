/* General Story Styling */
.story {
  background-size: cover;
  background-position: center;
  border-radius: 0px;
  margin-top: -3.2rem;
  position: relative;
  min-height: 1400px;
  transition: background-image 1s ease-out;
}

.story-container {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding: 0 2rem;
}

/* Title Styling */
/* Title Styling */
.story-title {
  font-size: 2.5rem;
  color: powderblue;
  font-family: "Bungee", sans-serif; /* Title font */
  position: absolute;
  top: 24px;
  right: -160px;
  text-align: right;
  line-height: 1.2;
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.story-title .bold {
  font-weight: bold;
}

.story-title .light {
  font-family: "Lexend Deca", sans-serif; /* Subtitle font */
  font-weight: 100; /* Adjust weight if needed */
  display: block;
  text-transform: uppercase;
  font-size: 25;
  word-spacing: 10;
}


/* Text Content Styling */
.story-text {
  position: absolute;
  right: 45rem;
  top: 22rem;
  min-width: 430px;
  background: transparent;
  padding: 1.5rem;
  border-radius: 8px;
  color: powderblue;
  animation: slideIn 1s forwards ease-out;
  font-family: "Genos", sans-serif;
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.story-text p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem; /* Added spacing between paragraphs */
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
}


.nav-button.left {
  left: -190px;
  margin-top: 30rem;
}

.nav-button.right {
  right: -190px;
  margin-top: 30rem;
}

/* Adjustments for the Second Story Background */
.story-variant .story-title {
  top: 24px;
  left: 24px;
  right: auto;
  text-align: left;
  color: #070838;
}

.story-variant .story-text {
  right: 20%;
  left: 70%;
  transform: translateY(-50%);
  top: 200px;
}

/* Styling for st3 (matching st1's layout) */
.story-st3 .story-title {
  top: 24px;
  right: -160px;
  text-align: right;
}

.story-st3 .story-text {
  right: 45rem;
  top: 20rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-container {
    padding: 1rem;
  }

  .story-title {
    font-size: 1.75rem;
    position: static;
    text-align: center;
    margin-bottom: 1rem;
    right: 0;
  }

  .story-variant .story-title {
    text-align: center;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
  }

  .story-text {
    max-width: 90%;
    position: static;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    min-width: auto;
    top: auto;
  }

  .story-variant .story-text {
    top: auto;
    transform: translateX(-50%);
    left: 50%;
  }

  .nav-button {
    width: 50px;
    height: 40px;
    margin-top: 20rem;
  }

  .nav-button svg {
    width: 25px;
    height: 25px;
  }

  .nav-button.left {
    left: 10px;
  }

  .nav-button.right {
    right: 10px;
  }
}

/* Keyframe Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
