/* JoinFlock Container */
.join-flock {
  position: relative;
  width: 100%;
  height: 1000px;
  margin: 0 !important;
  background-image: url(/public/joinbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  background-attachment: fixed; /* Fix the background */
}

/* Sliding Image (char.png) */
.char-image-container {
  position: absolute;
  top: 80px; /* Adjust for vertical alignment */
  left: 550px; /* Adjust for horizontal alignment */
  z-index: 2; /* Above the background and below other elements */
  background: transparent;
}

.char-image {
  width: 1080px; /* Adjust size as needed */
  height: 920px;
  margin-left: -50px;
  object-fit: contain;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* JoinFlock Title Background */
.join-flock-title-bg {
  position: relative;
  display: inline-block;
  background-image: url(/public/par.png); /* Use your provided image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 900px;
  height: 150px;
  margin-top: 400px;
  right: 15%;
}

/* Description */
.join-flock-description {
  font-family: "Genos", sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 30px 220px;
  max-width: 600px;
  background-color: #242020;
  color: powderblue;
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
}

/* OpenSea Button */
.join-flock-button-container {
  position: relative;
  display: inline-block;
  text-align: right;
  right: 20%;
}

.join-flock-gp {
  display: block;
  width: 300px;
  height: auto;
  z-index: 1;
  margin: 0 auto;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.join-flock-gp:hover {
  transform: scale(1.1) rotate(5deg); /* Scaling and rotation effect */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Optional shadow effect */
}

/* Social Buttons */
.social-buttons {
  position: absolute;
  top: 550px;
  right: 60%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  gap: 40px;
  margin-top: 300px;
}

.social-icon {
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

/* Text Styling for Button */
.join-flock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Bungee", sans-serif;
  font-size: 1.4rem;
  color: powderblue;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional text shadow */
  z-index: 2;
  pointer-events: none; /* Ensures text does not block clicks */
  transition: transform 0.3s ease, color 0.3s ease, text-shadow 0.3s ease;
}

.join-flock-text:hover {
  transform: translate(-50%, -50%) scale(1.1); /* Text scales along with the container */
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7); /* Enhanced shadow on hover */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .join-flock-title-bg {
    width: 500px;
    height: 120px;
    right: 10%;
  }

  .join-flock-gp {
    max-width: 200px;
  }

  .social-buttons {
    top: 65%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

  .char-image-container {
    top: 150px;
    left: 20px; /* Adjust position for smaller screens */
  }

  .char-image {
    width: 200px; /* Scale down size */
  }
}

@media screen and (max-width: 450px) {
  .join-flock-button-container {
    max-width: 90%;
  }

  .join-flock-gp {
    max-width: 200px;
  }

  .join-flock-title-bg {
    width: 300px;
    height: 100px;
    margin-top: 300px;
    right: 0;
  }

  .social-buttons {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .social-icon {
    width: 35px;
    height: 35px;
  }

  .char-image-container {
    top: 100px;
    left: 10px;
  }

  .char-image {
    width: 150px;
  }
}
